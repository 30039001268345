import * as Sentry from '@sentry/react';
import { isNative } from '@/utils/crossPlatform';
import { AppLaunchStatus } from '@/native/AppLaunchStatus/AppLaunchStatusPlugin';

// Register keys here upon app launch to maintain their status across web app reloads
// This ensures that these keys are persisted throughout the app's lifecycle
export const AppLaunchStatusKeys = {
  APP_PIN_VERIFICATION_NEEDED: 'appPinVerificationNeeded',
  APP_MFA_REQUEST_ID: 'appMfaRequestId',
  APP_FCM_TOKEN: 'appPushToken',
};

// Initial values for the keys, can be null if not known
const initialAppLaunchStatusKeys: { [key: string]: string } = {
  [AppLaunchStatusKeys.APP_PIN_VERIFICATION_NEEDED]: 'true',
  [AppLaunchStatusKeys.APP_MFA_REQUEST_ID]: '',
  [AppLaunchStatusKeys.APP_FCM_TOKEN]: '',
};

class AppLaunchStatusUtil {
  static async registerKeys(): Promise<void> {
    if (!AppLaunchStatus || !isNative()) return;
    try {
      const keys = Object.entries(initialAppLaunchStatusKeys).map(([key, value]) => ({
        key,
        value,
      }));
      await AppLaunchStatus.registerKeys({ keys });
    } catch (error) {
      console.error('Failed to register keys:', error);
      Sentry.captureException(error);
    }
  }

  static async getStatus(key: string): Promise<string> {
    if (!AppLaunchStatus || !isNative()) return '';
    try {
      const result = await AppLaunchStatus.getStatus({ key });
      return result.value;
    } catch (error) {
      console.error(`Failed to retrieve status for key "${key}":`, error);
      Sentry.captureException(error);
      return '';
    }
  }

  static async setStatus(key: string, value: string): Promise<void> {
    if (!AppLaunchStatus || !isNative()) return;
    try {
      await AppLaunchStatus.setStatus({ key, value });
    } catch (error) {
      console.error(`Failed to set status for key "${key}":`, error);
      Sentry.captureException(error);
    }
  }

  static async setBooleanStatus(key: string, value: boolean): Promise<void> {
    await AppLaunchStatusUtil.setStatus(key, value.toString());
  }

  static async getBooleanStatus(key: string): Promise<boolean> {
    const value = await AppLaunchStatusUtil.getStatus(key);
    return value === 'true';
  }
}

export { AppLaunchStatusUtil };
